<template>
    <div>
  
      <!-- Navigation Bar -->
      <nav class="navbar">
        <div class="logo">
          <i class="fas fa-shipping-fast"></i> 
        </div>
        <div class="menu-toggle" @click="toggleMenu">
          <i class="fas" :class="isMenuOpen ? 'fa-times' : 'fa-bars'"></i>
        </div>
        <div class="nav-links" :class="{ 'nav-active': isMenuOpen }">
          <a href="#home"><!--i class="fas fa-home"></i--> Home</a>
          <a href="#work"><!--i class="fas fa-briefcase"></i--> Our Work</a>
          <a href="#services"><!--i class="fas fa-cogs"></i--> Services</a>
          <a href="#testimonials"><!--i class="fas fa-comment-dots"></i--> Testimonials</a>
          <a href="#contact"><!--i class="fas fa-envelope"></i--> Contact</a>
          <a href="#partners"><!--i class="fas fa-handshake"></i--> Partners</a>
        </div>
        <select id="language-switcher">
          <option value="en">🌐 English</option>
          <option value="fr">🌐 Français</option>
        </select>
      </nav>

      <!-- Header Section -->
      <header class="hero-banner">
        <h1><!--i class="fas fa-truck-moving"></i--> C.C ENTERPRISE</h1>
        <p>Big or Small, We Move it All</p>
        <button>
          <i class="fas fa-quote-left"></i> Get a Free Quote <i class="fas fa-quote-right"></i>
        </button>
      </header>
      <!-- Company Pitch Section -->
      <section id="company-pitch" class="pitch-section">
        <h2><i class="fas fa-flag-checkered"></i> Why Customers Choose Us</h2>
        <p>
          With over two decades of experience, we have earned our reputation as Ottawa’s most trusted movers. At C.C. ENTERPRISE, we specialize in delivering seamless and stress-free moving services, free of hidden fees.
        </p>
      </section>
  
      <!-- Services Section -->
      <section id="services" class="services-section">
        <h2><i class="fas fa-concierge-bell"></i> Our Services</h2>
        <div class="services-container">
          <div class="service-card">
            <h3><i class="fas fa-home"></i> Residential Moving</h3>
            <p>Effortless relocation for your home.</p>
          </div>
          <div class="service-card">
            <h3><i class="fas fa-building"></i> Office Relocation</h3>
            <p>Seamless transitions for your business.</p>
          </div>
          <div class="service-card">
            <h3><i class="fas fa-box"></i> Packing Services</h3>
            <p>Secure packing for peace of mind.</p>
          </div>
        </div>
      </section>
  
      <!-- Testimonials Section -->
      <section id="testimonials" class="testimonials-section">
        <h2><i class="fas fa-star"></i> What Our Clients Say</h2>
        <p><i class="fas fa-quote-left"></i> Fantastic service! Everything was smooth and stress-free. <i class="fas fa-quote-right"></i></p>
      </section>
  
      <!-- Partners Section -->
      <section id="partners" class="partners-section">
        <h2><i class="fas fa-handshake"></i> Our Partners</h2>
        <div class="partners-container">
          <div class="partner-card">
            <a href="https://www.ottawabuildersales.com/" target="_blank" rel="noopener noreferrer" aria-label="OttawaBuilders"> 
              <img src="@/assets/partner-logo-ottawa-builders.jpg" alt="Ottawa Builders Sales Logo" class="banner-image" />
            </a>
        </div>

          <div class="partner-card">Partner Logo 2</div>
          <div class="partner-card">Partner Logo 3</div>
        </div>
      </section>
  
    <!-- Footer -->
        <footer class="footer">
            <div class="social-icons">
                <a href="https://www.facebook.com/profile.php?id=100089692933924" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
                <i class="fab fa-facebook-f"></i>
                </a>
                <a href="https://www.instagram.com/c.c.enterprise_moving" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                <i class="fab fa-instagram"></i>
                </a>
                <a href="#" target="_blank" rel="noopener noreferrer" aria-label="X">
                <i class="fab fa-x-twitter"></i>
                </a>
                <a href="mailto:corey@ccenterprise.ca" aria-label="Email">
                <i class="fas fa-envelope"></i>
                </a>
                <a href="#" target="_blank" rel="noopener noreferrer" aria-label="TikTok">
                <i class="fab fa-tiktok"></i>
                </a>
            </div>
            <p>
            &copy; 2024 C.C ENTERPRISE | All rights reserved. <i class="fas fa-heart"></i>
            </p>
        </footer>

    </div>
  </template>
  
  <script>
  export default {
    name: "HomePage",
    data() {
      return {
        isMenuOpen: false,
      };
    },
    methods: {
      toggleMenu() {
        this.isMenuOpen = !this.isMenuOpen;
      },
    },
  };
  </script>
  
  <style scoped>
  body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #f4f4f4;
    color: #333;
  }
  
  .hero-banner {
    background-image: url('@/assets/white hero truck.jpg');
    background-size: cover;
    background-position: center;
    height: 500px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    align-items: center;
    text-align: center;
    color: #0f0fda;
  }
  
  .hero-banner h1 {
    font-size: 3rem;
    margin: 0;
  }
  .hero-banner p {
    font-size: 1.6rem;
    margin: 0;
  }
  .hero-banner button {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1.5rem;
    background-color: #00AEEF;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .navbar {
    background: black;
    color: white;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  
  .menu-toggle {
    display: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .nav-links {
    display: flex;
    gap: 15px;
    align-items: center;
    transition: max-height 0.3s ease-in-out;
  }
  
  .nav-links a {
    color: white;
    text-decoration: none;
  }
  
  .nav-links a:hover {
    text-decoration: underline;
  }
  
  .nav-links.nav-active {
    max-height: 300px;
    display: block;
    flex-direction: column;
    text-align: center;
  }
  
  #language-switcher {
    margin-left: 10px;
  }
  
  .pitch-section {
    padding: 40px 20px;
    max-width: 1200px;
    margin: auto;
    text-align: center;
    background-color: #e0f7fa;
    border-radius: 8px;
  }
  
  .services-section .services-container {
    display: flex;
    justify-content: space-around;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .service-card {
    flex: 1;
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .testimonials-section,
  .partners-section {
    padding: 40px 20px;
    max-width: 1200px;
    margin: auto;
    text-align: center;
    background-color: #d3f8c8;

  }
  
  .partners-container {
    display: flex;
    justify-content: space-around;
    gap: 20px;
  }
  
  .banner-image {
    width: 200px;
    height: auto;
    object-fit: cover;
  }

  .footer {
    background: black;
    color: white;
    text-align: center;
    padding: 20px 0;
  }

  .footer .social-icons {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  gap: 15px;
}
  
  /* Responsive Styles */
  @media screen and (max-width: 768px) {
    .menu-toggle {
      display: block;
    }
  
    .nav-links {
      display: none;
      max-height: 0;
      overflow: hidden;
      flex-direction: column;
      width: 100%;
      background: black;
      position: absolute;
      top: 60px;
      left: 0;
      padding: 10px 0;
    }
  
    .nav-links.nav-active {
      display: flex;
    }
  }
  </style>
  